.App {
  padding-top: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding-bottom: 20px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  padding-top: 24px;
  text-align: center;
}

.App-header img {
  width: 100%;
}

.App-container {
  margin: auto;
  /* max-width: 800px; */
  max-width: 1600px;
  background-color: white;
  padding: 0 20px 0 20px;
  box-shadow: 0 0 5px silver;
  padding-bottom: 20px;
}

.App-libraryContainer {
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 1500px;
  background-color: white;
  /* display: flex;
  flex-wrap: wrap; */
  /* column-count: 2; */
  /* columns: 2 auto; */
}

.App-library-item-row {
  margin-bottom: 2em;
}

.App-libraryItem {
  width: 100%;
  height: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 10px silver;
}

.App-libraryItem:hover {
  box-shadow: 0 0 25px rgb(110, 110, 110);
}

.Picture {
  width: 120px;
  /* height: auto; */
  /* max-height: 120px; */
}

.App-libraryItem a {
  display: grid;

  justify-content: stretch;
  text-decoration: none;
  height: 100%;
  color: black;
}

.App-libraryItem a:hover {
  color: black;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
}

/* 2/3 column */
.column-66 {
  float: left;
  width: 66.66666%;
  padding: 20px;
}

/* 1/3 column */
.column-33 {
  float: left;
  width: 33.33333%;
  padding: 20px;
  display: flex;
  justify-content: center;

}

.container {
  padding: 30px;
}

.footer {
  text-align: center;
  margin-bottom: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(126, 125, 125);
}

.footer a {
  text-decoration: none;
  color: rgb(130, 192, 255);

}

@media only screen and (max-width: 575px) {

  .column-66,
  .column-33 {
    width: 100%;
    text-align: center;
  }

  .App-libraryItem {
    width: 100%;
    height: auto;

  }
}

@media only screen and (max-width: 992px) {
  .App-container {
    margin: auto;
    max-width: 800px;
    background-color: white;
    padding: 0 20px 0 20px;
    box-shadow: 0 0 5px white;
    padding-bottom: 20px;
  }

  .App-libraryItem {
    height: auto;
    min-height: 100px;
  }

  .App-library-item-row {
    margin-bottom: 0;
  }
}